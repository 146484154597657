.table {
  th,
  td {
    padding: 0.5rem;
  }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
}

.table-unstriped {
  tbody > tr {
    background-color: $color-white-gradient2;
  }
}
