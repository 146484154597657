.card {
  &.collapsible-card {
    margin-bottom: 1.25rem;

    .card-body {
      background-color: $color-wharton-marine-gray;
    }
  }

  .card-header {
    font-size: 1.125rem;
    font-weight: 400;

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
