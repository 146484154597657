.navbar {
  background-color: $color-wharton-main-blue;

  .navbar-nav {
    align-items: center;
  }

  .navbar-brand {
    font-size: 1.67rem;
  }

  .nav-item-user-info {
    color: $color-white;
    text-align: center;

    .nav-item-user-title {
      font-size: 0.875rem;
    }
  }

  .nav-item-dropdown-menu {
    &.show {
      .nav-link {
        color: $color-black;
        background-color: #e6e6e6;
      }
    }

    .nav-link {
      font-size: 1.5rem;
      color: $color-white;
      background-color: transparent;
      border: none;
      box-shadow: none;
      border-radius: 0;

      &:hover {
        color: $color-black;
        background-color: #e6e6e6;
      }
    }
  }
}
