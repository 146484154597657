// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  $active-background: darken($background, 10%);
  $active-border: darken($border, 12%);

  color: $color;
  background-color: $background;
  border-color: $border;
  //@ include box-shadow(inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075));
  @include box-shadow(inset 0 1px 0 rgba(255,255,255,.15));

  @include hover {
    color: $color;
    background-color: $active-background;
        border-color: $active-border;
  }

  &:focus,
  &.focus {
    color: $color;
    background-color: $active-background;
        border-color: $active-border;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
        border-color: $active-border;
    // Remove the gradient for the pressed/active state
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: $background;
          border-color: $border;
    }
    @include hover {
      background-color: $background;
          border-color: $border;
    }
  }
}

@mixin button-outline-variant($color) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: $color;

  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: #fff;
    background-color: $color;
        border-color: $color;
  }
  @include hover {
    color: #fff;
    background-color: $color;
        border-color: $color;
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      border-color: lighten($color, 20%);
    }
    @include hover {
      border-color: lighten($color, 20%);
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  @include border-radius($border-radius);
}
