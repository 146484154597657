// ----------------------------------------------------------------------------------
// Sass and CSS by Kim Vigsbo | StoneHill Media, Inc. | http://www.stonehillmedia.com
// ----------------------------------------------------------------------------------
@import 'mixins';
@import 'buttons';
// ----------------------------------------------------------------------------------

/* --- colors --- */

$color-wharton-main-blue: #004785;
$color-wharton-midnight-blue: #262460;
$color-wharton-atlantic-blue: #282f85;
$color-wharton-pacific-blue: #026cb5;
$color-wharton-bay-blue: #06aafc;

$color-wharton-main-red: #a90533;
$color-wharton-evening-red: #532a85;
$color-wharton-sunset-red: #96227d;
$color-wharton-brick-red: #a8204e;
$color-wharton-heritage-red: #c5093b;

$color-wharton-night-street: #2d2c41;
$color-wharton-morning-yellow: #d7bc6a;
$color-wharton-college-gray: #b2b6a7;
$color-wharton-marine-gray: #eeedea;

$color-white-gradient1: #ffffff;
$color-white-gradient2: #fafafa;

$color-white: #ffffff;
$color-black: $color-wharton-night-street;

/* --- settings --- */

$font-size: 16px !important;
$line-height: 1.5 !important;
$font-family: 'Lato', Verdana, sans-serif;
$font-weight-normal: 300;
$font-weight-bold: 400;
$border-radius: 6px;

/* --- break points --- */

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

/* --- standard colors --- */

.color-white {
  color: $color-white;
}

.color-black {
  color: $color-wharton-night-street;
}

/* --- special colors --- */

.color-wharton-main-blue {
  color: $color-wharton-main-blue;
}

.color-wharton-midnight-blue {
  color: $color-wharton-midnight-blue;
}

.color-wharton-atlantic-blue {
  color: $color-wharton-atlantic-blue;
}

.color-wharton-pacific-blue {
  color: $color-wharton-pacific-blue;
}

.color-wharton-bay-blue {
  color: $color-wharton-bay-blue;
}

.color-wharton-main-red {
  color: $color-wharton-main-red;
}

.color-wharton-evening-red {
  color: $color-wharton-evening-red;
}

.color-wharton-sunset-red {
  color: $color-wharton-sunset-red;
}

.color-wharton-brick-red {
  color: $color-wharton-brick-red;
}

.color-wharton-heritage-red {
  color: $color-wharton-heritage-red;
}

.color-wharton-night-street {
  color: $color-wharton-night-street;
}

.color-wharton-morning-yellow {
  color: $color-wharton-morning-yellow;
}

.color-wharton-college-gray {
  color: $color-wharton-college-gray;
}

.color-wharton-marine-gray {
  color: $color-wharton-marine-gray;
}

/* --- standard bootstrap colors --- */

.color-bootstrap-muted {
  color: $bootstrap-color-text-muted;
}

.color-bootstrap-primary {
  color: $bootstrap-color-text-primary;
}

.color-bootstrap-warning {
  color: $bootstrap-color-text-warning;
}

.color-bootstrap-danger {
  color: $bootstrap-color-text-danger;
}

.color-bootstrap-success {
  color: $bootstrap-color-text-success;
}

.color-bootstrap-info {
  color: $bootstrap-color-text-info;
}

/* --- standard background colors --- */

.bg-color-white {
  background-color: $color-white;
}

.bg-color-black {
  background-color: $color-wharton-night-street;
}

/* --- special background colors --- */

.bg-wharton-main-blue {
  background-color: $color-wharton-main-blue;
}

.bg-wharton-midnight-blue {
  background-color: $color-wharton-midnight-blue;
}

.bg-wharton-atlantic-blue {
  background-color: $color-wharton-atlantic-blue;
}

.bg-wharton-pacific-blue {
  background-color: $color-wharton-pacific-blue;
}

.bg-wharton-bay-blue {
  background-color: $color-wharton-bay-blue;
}

.bg-wharton-main-red {
  background-color: $color-wharton-main-red;
}

.bg-wharton-evening-red {
  background-color: $color-wharton-evening-red;
}

.bg-wharton-sunset-red {
  background-color: $color-wharton-sunset-red;
}

.bg-wharton-brick-red {
  background-color: $color-wharton-brick-red;
}

.bg-wharton-heritage-red {
  background-color: $color-wharton-heritage-red;
}

.bg-wharton-night-street {
  background-color: $color-wharton-night-street;
}

.bg-wharton-morning-yellow {
  background-color: $color-wharton-morning-yellow;
}

.bg-wharton-college-gray {
  background-color: $color-wharton-college-gray;
}

.bg-wharton-marine-gray {
  background-color: $color-wharton-marine-gray;
}

/* --- labels --- */

.label-blue {
  background-color: $color-wharton-pacific-blue;
}

.label-green {
  background-color: #008000;
}

.label-red {
  background-color: $color-wharton-main-red;
}

/* --- text settings --- */

.text-300 {
  font-weight: 300;
}

.text-400 {
  font-weight: 400;
}

.small14 {
  font-size: 14px;
}

.small16 {
  font-size: 16px;
}

/* --- solid buttons --- */

.btn-main-blue {
  @include button-variant(
    $color-white,
    $color-wharton-main-blue,
    $color-wharton-main-blue
  );
}

.btn-midnight-blue {
  @include button-variant(
    $color-white,
    $color-wharton-midnight-blue,
    $color-wharton-midnight-blue
  );
}

.btn-atlantic-blue {
  @include button-variant(
    $color-white,
    $color-wharton-atlantic-blue,
    $color-wharton-atlantic-blue
  );
}

.btn-pacific-blue {
  @include button-variant(
    $color-white,
    $color-wharton-pacific-blue,
    $color-wharton-pacific-blue
  );
}

.btn-bay-blue {
  @include button-variant(
    $color-white,
    $color-wharton-bay-blue,
    $color-wharton-bay-blue
  );
}

.btn-main-red {
  @include button-variant(
    $color-white,
    $color-wharton-main-red,
    $color-wharton-main-red
  );
}

.btn-evening-red {
  @include button-variant(
    $color-white,
    $color-wharton-evening-red,
    $color-wharton-evening-red
  );
}

.btn-sunset-red {
  @include button-variant(
    $color-white,
    $color-wharton-sunset-red,
    $color-wharton-sunset-red
  );
}

.btn-brick-red {
  @include button-variant(
    $color-white,
    $color-wharton-brick-red,
    $color-wharton-brick-red
  );
}

.btn-heritage-red {
  @include button-variant(
    $color-white,
    $color-wharton-heritage-red,
    $color-wharton-heritage-red
  );
}

.btn-night-street {
  @include button-variant(
    $color-white,
    $color-wharton-night-street,
    $color-wharton-night-street
  );
}

.btn-morning-yellow {
  @include button-variant(
    $color-white,
    $color-wharton-morning-yellow,
    $color-wharton-morning-yellow
  );
}

.btn-college-gray {
  @include button-variant(
    $color-white,
    $color-wharton-college-gray,
    $color-wharton-college-gray
  );
}

.btn-marine-gray {
  @include button-variant(
    $color-black,
    $color-wharton-marine-gray,
    $color-wharton-marine-gray
  );
}

/* --- outline buttons --- */

.btn-main-blue-outline {
  @include button-outline-variant($color-wharton-main-blue);
}

.btn-midnight-blue-outline {
  @include button-outline-variant($color-wharton-midnight-blue);
}

.btn-atlantic-blue-outline {
  @include button-outline-variant($color-wharton-atlantic-blue);
}

.btn-pacific-blue-outline {
  @include button-outline-variant($color-wharton-pacific-blue);
}

.btn-bay-blue-outline {
  @include button-outline-variant($color-wharton-bay-blue);
}

.btn-main-red-outline {
  @include button-outline-variant($color-wharton-main-red);
}

.btn-evening-red-outline {
  @include button-outline-variant($color-wharton-evening-red);
}

.btn-sunset-red-outline {
  @include button-outline-variant($color-wharton-sunset-red);
}

.btn-brick-red-outline {
  @include button-outline-variant($color-wharton-brick-red);
}

.btn-heritage-red-outline {
  @include button-outline-variant($color-wharton-heritage-red);
}

.btn-night-street-outline {
  @include button-outline-variant($color-wharton-night-street);
}

.btn-morning-yellow-outline {
  @include button-outline-variant($color-wharton-morning-yellow);
}

.btn-college-gray-outline {
  @include button-outline-variant($color-wharton-college-gray);
}

.btn-marine-gray-outline {
  @include button-outline-variant($color-wharton-marine-gray);
}

/* --- buttons with icons --- */

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-example {
  margin-bottom: 6px;
}

.btn-selectpicker-sm {
  font-size: 14px !important;
  padding: 4px 10px !important;
}

/* --- html and body --- */

html {
  position: relative;
  min-height: 100%;
}

body {
  color: $color-black;
  background-color: $color-white;
  font-family: $font-family;
  font-weight: $font-weight-normal;
  font-size: $font-size;
  margin-top: 80px;
}

/* --- misc --- */

.fa-sort-icon {
  color: $color-wharton-college-gray;
  margin-top: 4px;
  cursor: pointer;
  font-size: 110%; // 40px;
}

.fa-icon-only {
  font-size: 40px;
}

.projects-wrapper {
  background-color: $color-wharton-marine-gray;
  margin-bottom: 20px;
  padding: 20px;
  @include border-radius(4px);
}

.projects-wrapper-special {
  background-color: $color-wharton-marine-gray;
  margin-bottom: 20px;
  padding: 20px 20px 8px 20px;
  @include border-radius(4px);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: $font-family;
  font-weight: $font-weight-normal;
  color: $color-wharton-pacific-blue;

  small {
    font-family: $font-family;
    font-weight: $font-weight-normal;
  }
}

h1 {
  font-size: 22px;
  margin-bottom: 20px;

  &.header-smaller {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &.white {
    font-size: 32px;
    color: $color-white;
    margin-bottom: 30px;
    font-family: $font-family;
    font-weight: $font-weight-normal;
  }
}

strong {
  font-weight: 400 !important;
}

img.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a:active,
a:focus,
:active,
:focus {
  outline: none;
  -moz-outline-style: none;
}

.fa-ml {
  margin-left: 8px;
}

.fa-mr {
  margin-right: 8px;
}

.badge {
  font-weight: 300;
  font-size: 14px;
}

.list-exhibits li {
  margin-top: 8px;
}

.scroll-table-wrapper {
  border: 1px solid darken($color-wharton-marine-gray, 5%);
  width: 100%;
  height: 60vh;
  overflow: auto;
}

.list-group-item-blue,
.list-group-item-blue:hover {
  font-weight: 400;
  color: $color-white !important;
  background-color: $color-wharton-main-blue !important;
}

.list-group-item-red,
.list-group-item-red:hover {
  font-weight: 400;
  color: $color-white !important;
  background-color: $color-wharton-heritage-red !important;
}

.table-value {
  float: left;
}

.table.tablex > tbody > tr > td,
.table.tablex > tbody > tr > th,
.table.tablex > tfoot > tr > td,
.table.tablex > tfoot > tr > th,
.table.tablex > thead > tr > td,
.table.tablex > thead > tr > th {
  padding: 4px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: none; //1px solid #ddd;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: $color-white;
}

/* --- nav button --- */

a.nav-button {
  display: block;
  padding: 20px 20px 20px 20px;
  @include border-radius($border-radius);
  font-size: 22px;
  color: $color-wharton-main-blue;
  text-decoration: none;
  background-color: $color-wharton-marine-gray;
  margin-bottom: 16px;
}

a.nav-button:hover,
a.nav-button:focus {
  color: $color-white;
  text-decoration: none;
  background-color: $color-wharton-bay-blue;
}

a.nav-button-player {
  display: block;
  padding: 20px 20px 20px 20px;
  @include border-radius($border-radius);
  font-size: 22px;
  color: $color-wharton-main-blue;
  text-decoration: none;
  background-color: $color-wharton-college-gray;
  margin-bottom: 16px;
}

a.nav-button-player:hover,
a.nav-button-player:focus {
  color: $color-white;
  text-decoration: none;
  background-color: $color-wharton-bay-blue;
}

a.nav-button-player-green {
  display: block;
  padding: 20px 20px 20px 20px;
  @include border-radius($border-radius);
  font-size: 22px;
  color: $color-white;
  text-decoration: none;
  background-color: $bootstrap-color-text-success;
  margin-bottom: 16px;
}

a.nav-button-player-green:hover,
a.nav-button-player-green:focus {
  color: $color-white;
  text-decoration: none;
  background-color: $color-wharton-bay-blue;
}

a.nav-button-player-special {
  height: 103px;
  display: block;
  padding: 20px 20px 20px 20px;
  @include border-radius($border-radius);
  font-size: 22px;
  color: $color-wharton-main-blue;
  text-decoration: none;
  background-color: $color-wharton-college-gray;
  margin-bottom: 20px;
}

a.nav-button-player-special:hover,
a.nav-button-player-special:focus {
  color: $color-white;
  text-decoration: none;
  background-color: $color-wharton-bay-blue;
}

.panel-player-blue {
  background-color: $color-white;
  @include border-radius($border-radius);
  margin-bottom: 20px;
}

.panel-player-blue-icon {
  background-color: $color-wharton-bay-blue;
  @include border-radiuses(0, 0, $bottomleft: 4px, $topleft: 4px);
  padding: 12px;
}

.panel-player-blue-text {
  //background-color: $color-wharton-bay-blue;
  padding: 12px;
}

.panel-player-red {
  background-color: $color-white;
  @include border-radius($border-radius);
}

.panel-player-red-icon {
  background-color: $color-wharton-heritage-red;
  @include border-radiuses(0, 0, $bottomleft: 4px, $topleft: 4px);
  padding: 12px;
}

.panel-player-red-text {
  //background-color: $color-wharton-bay-blue;
  padding: 12px;
}

/* --- button settings --- */

.btn-xlg {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.227;
}

.btn-xxlg {
  padding: 20px 26px;
  font-size: 35px;
}

.btn-ml {
  margin-left: 8px;
}

.btn-mr {
  margin-right: 8px;
}

.btn-mt {
  margin-top: 4px;
}

.btn-mb {
  margin-bottom: 4px;
}

.nowrap {
  white-space: nowrap;
}

/* --- navbar - top --- */

.navbar-top {
  padding: 8px 0 8px 0;
  background-color: $color-wharton-main-blue;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-top-example {
  padding: 8px 0 8px 0;
  background-color: $color-wharton-main-blue;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar-top-text-header {
  font-size: 28px;
  color: $color-white !important;
  white-space: nowrap;

  span {
    color: $color-wharton-bay-blue;
    font-size: 28px;
    margin-left: 12px;
  }
}

.navbar-top-avatar {
  border: 2px solid $color-white;
  margin-right: 12px;
}

.navbar-top-user-info {
  white-space: nowrap;
}

.navbar-top-user-name {
  color: $color-white;
}

.navbar-top-user-title {
  color: $color-white;
  font-size: 14px;
}

.navbar-top-mr {
  margin-right: 20px;
}

.navbar-top-ml {
  margin-left: 20px;
}

.img-outline {
  border: 2px solid $color-white;
}

.btn-navbar-top-menu {
  font-size: 24px;
  color: $color-white;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin-left: 30px;

  i {
    margin-right: 8px;
  }
}

/* --- content bg --- */

.content-wrapper {
  margin-bottom: 60px;
}

.content-bg {
  width: 100%;
  color: $color-black;
  background-color: $color-white;
  padding: 30px 5px 30px 5px;
}

/* --- panels --- */

.panel-projects {
  border-color: #eeedea;
}

.panel {
  margin-bottom: 20px;
  background-color: #eeedea;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel-title {
  font-size: 18px;
  font-weight: 400;
}

.panel-heading .accordion-toggle h4:after {
  /* symbol for "opening" panels */
  font-family: 'FontAwesome';
  content: '\f077';
  float: right;
  color: grey;
  overflow: no-display;
  font-weight: 300;
}

.panel-heading .accordion-toggle.collapsed h4:after {
  /* symbol for "collapsed" panels */
  content: '\f078';
  font-weight: 300;
}

a.accordion-toggle {
  text-decoration: none;
}

.upload-panel {
  background-color: $color-white;
  padding: 20px;
  margin-top: 30px;
  border-radius: 4px;
}

/* --- search --- */

.search-right-inner-addon {
  position: relative;
}

.search-right-inner-addon input {
  padding-right: 30px;
}

.search-right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  font-weight: 300;
  pointer-events: none;
}

.search-icon {
  font-size: 14px;
}

/* --- modal --- */

.modal-header-wharton {
  background-color: $color-wharton-bay-blue;
  @include border-radiuses(
    $topright: 4px,
    $bottomright: 0,
    $bottomleft: 0,
    $topleft: 4px
  );
}

.modal-header-wharton .modal-title {
  color: $color-white;
}

/* --- tables --- */

table thead th {
  font-weight: 400;
}

.table-wharton-main-blue {
  color: $color-white;
  background-color: $color-wharton-main-blue;
}

.table-wharton-pacific-blue {
  color: $color-white;
  background-color: $color-wharton-pacific-blue;
}

.table-wharton-bay-blue {
  color: $color-white;
  background-color: $color-wharton-bay-blue;
}

.table-wharton-main-red {
  color: $color-white;
  background-color: $color-wharton-main-red;
}

.table-wharton-header-main-blue {
  color: $color-white;
  background-color: $color-wharton-main-blue;
}

.table-wharton-header-main-red {
  color: $color-white;
  background-color: $color-wharton-main-red;
}

/* --- forms --- */

label {
  font-weight: 400;
}

.form-control {
  font-size: 16px !important;
}

textarea {
  resize: none;
}

/* --- menu bar --- */

.menu-bar {
  padding: 12px;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

/*
.menu-bar-inner {
	margin-bottom: 4px;
}
*/

.menu-bar-header {
  font-size: 22px;
}

/*
.menu-bar-margin-top {
	margin-top: 20px;
}

.menu-bar-margin-bottom {
	margin-bottom: 20px;
}
*/

/* --- footer --- */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: $color-wharton-main-blue;
  z-index: 4;
}

.footer-logo {
  float: left;
  margin-right: 20px;
  margin-top: 11px;
}

.footer-text {
  font-size: 12px;
  color: $color-white;
  line-height: 1.2;
  margin-top: 15px;
}

.footer-right {
  color: $color-white;
  margin-top: 20px;
  float: right;
}

/* --- login --- */

.login {
  background-color: #159957;
  background-image: linear-gradient(120deg, #155799, #159957);
  margin-top: 0 !important;
  height: 100vh;
}

.login-box {
  margin-top: 200px;
}

.login-header {
  font-size: 50px;
  text-align: center;
  margin: 12px 0 12px 0;
  color: $color-wharton-pacific-blue;
}

.login-header-sub {
  font-size: 20px;
  color: $color-wharton-pacific-blue;
  text-align: center;
  margin-bottom: 40px;
}

.login-box-buttons {
  margin-top: 20px;
}

.panel-footer {
  padding: 6px 18px;
  background-color: $color-wharton-main-blue;
}

.login-footer {
  width: 100%;
  padding: 4px 0 10px 0;
}

.login-footer-logo {
  float: left;
  margin-right: 20px;
  margin-top: 5px;
}

.login-footer-text {
  font-size: 12px;
  color: $color-white;
  line-height: 1.2;
  margin-top: 10px;
}

.login-footer-right {
  float: right;
  margin-top: 10px;
}

video#bgvid {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

@media screen and (max-width: 767px) {
  #bgvid {
    display: none !important;
  }

  .login-box {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}

.logout-text {
  font-size: 22px;
  color: $color-black;
  text-align: center;
  padding: 20px 0 20px 0;
}

.btn-logout-margin {
  margin-right: 12px;
}

/* --- spacing --- */
.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-top-90 {
  margin-top: 90px;
}

.margin-bottom-90 {
  margin-bottom: 90px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

/* --- padding --- */
.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-top-90 {
  padding-top: 90px;
}

.padding-bottom-90 {
  padding-bottom: 90px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}
