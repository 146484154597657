// Override stonehillmedia style
body {
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: auto;

  &.admin-page {
    position: relative;
    min-height: 100vh;
  }

  &.has-header {
    padding-top: 80px;
    //background-color: red;
  }
}

.footer-logo {
  float: left;
  margin-right: 20px;
  margin-top: 0px;
}

.table-title {
  margin-left: -15px;
  font-size: 22px;
}

.login {
  background-color: #026cb5;
  background-image: linear-gradient(120deg, #026cb5, #96227d);
  margin-top: 0 !important;
  height: 100vh;
}

.login-form {
  margin-left: 20px;
  margin-right: 20px;
}

.breadcrumb-link {
  color: white;

  &:hover {
    color: white;
  }
}

.csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-icon {
  position: relative;
  display: inline-block;
  padding: 2px 0px;
  border-radius: 3px 0 0 3px;
}

.btn-icon-labelled {
  padding-top: 0px;
  padding-left: 6px;
  padding-bottom: 0px;
  padding-right: 6px;
}

.status-switch {
  position: relative;
  display: inline-block;
}

.stronger {
  font-weight: 550 !important;
}

.tall-row {
  margin-top: 5px;
  margin-bottom: 5px;
}
