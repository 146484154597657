$bootstrap-color-muted: #474949;
$bootstrap-color-primary: #428BCA;
$bootstrap-color-warning: #F0AD4E;
$bootstrap-color-danger: #D9534F;
$bootstrap-color-success: #5CB85C;
$bootstrap-color-info: #5BC0DE;

$bootstrap-color-text-muted: #999999;
$bootstrap-color-text-primary: #428BCA;
$bootstrap-color-text-warning: #C09853;
$bootstrap-color-text-danger: #B94A48;
$bootstrap-color-text-success: #468847;
$bootstrap-color-text-info: #3A87AD;

.bg-pink {
	background-color: pink;
}

.bg-yellow {
	background-color: yellow;
}

.bg-blue {
	background-color: blue;
}

.bg-green {
	background-color: green;
}

/*
.clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
		content: "";
	}
	&:after {
		clear: both;
	}
}
.clearfix {
	@include clearfix();
}
*/

/*
.opacity($opacity) {
	opacity: $opacity / 100;
	filter: alpha(opacity=$opacity);
}
*/

@mixin opacity($value){
	$IEValue: $value*100;
	opacity: $value;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
	filter: alpha(opacity=$IEValue);
}

@mixin gradient($startColor: #555, $endColor: #333) {
	background-color: mix($startColor, $endColor, 60%);
	background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
	background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
	background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
	background-image: -o-linear-gradient(top, $startColor, $endColor); // Opera 11.10
	background-image: linear-gradient(top, $startColor, $endColor); // The standard
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{darken($startColor, 1%)}', endColorstr='#{darken($endColor, 1%)}', GradientType=0); // IE9 and down
}

@mixin box-shadow($shadow-values: 0px 1px 1px 1px #949494) {
	-webkit-box-shadow: $shadow-values;
	-moz-box-shadow: $shadow-values;
	box-shadow: $shadow-values;
}

$enable-hover-media-query:  false !default;
@mixin hover {
	@if $enable-hover-media-query {
		// See Media Queries Level 4: http://drafts.csswg.org/mediaqueries/#hover
		// Currently shimmed by https://github.com/twbs/mq4-hover-shim
		@media (hover: hover) {
			&:hover { @content }
		}
	}
	@else {
		&:hover { @content }
	}
}

@mixin text-shadow ($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
	text-shadow: $string;
}

@mixin border-radius($radius) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;

	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
	-moz-border-radius-topleft: $topleft;

	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	border-top-left-radius: $topleft;

	-moz-background-clip: padding; 
	-webkit-background-clip: padding-box; 
	background-clip: padding-box; 
}

@mixin box-sizing($border-type: border-box) {
	-moz-box-sizing: $border-type;
	-webkit-box-sizing: $border-type;
	box-sizing: $border-type;
}
